import $ from "jquery";

$(function() {
    const $bl = $(".bl-referenzlist");
    if($bl.length == 0) {
        return;
    }

    const $tabs = $bl.find(".tabs li");
    const $tbody = $bl.find("table tbody");

    function switchKategorie(kategorie) {
        $tbody.find("tr").hide();
        $tbody.find("tr[data-kategorie="+kategorie+"]").show();
    }

    $bl.on("click", ".tabs li", function(ev) {
        ev.preventDefault();

        const $tab = $(this);
        $tabs.removeClass("is-active");
        $tab.addClass("is-active");

        const kategorie = $tab.find("a").data("kategorie");
        switchKategorie(kategorie);
    });

    const defaultKategorie = $tabs.filter(".is-active").first().find("a").data("kategorie");
    switchKategorie(defaultKategorie);

    function gotoHash() {
        const hash = window.location.hash;
        if(!hash && hash.length < 2) {
            return;
        }         
        const kat = hash.substring(1);
        $tabs.find("[data-kategorie="+kat+"]").trigger("click");
    }
    gotoHash();
    window.addEventListener("hashchange", gotoHash); 

});