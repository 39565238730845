import { ready } from "./utils.js";
import $ from "jquery";

ready(() => {
  const hamburger = document.getElementById("hamburger");

  if (hamburger) {
    hamburger.addEventListener("click", () => {
      hamburger.classList.toggle("is-active");
    });

    $(".js-off-canvas-overlay").on("click", function() {
      hamburger.classList.remove("is-active");
    });
  }
});
